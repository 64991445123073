import SemesterMixin from "@/views/mixins/semester.mixin";
import FrontMixins from "@/common/mixins/front-mixins";

export default {
    name: 'Calendar',
    mixins:[SemesterMixin,FrontMixins],
    data() {
        return {
            search: {
                use_date_range: false,
                date_range: [],
            },
            virtual_rooms:{}
        }
    },
    created() {
        this.model = 'User.Calendar';
        const date = new Date();
        this.search.date_range = [date.Format('yyyy-MM-dd'), date.Format('yyyy-MM-dd')];
        this.search.use_date_range = true;
        this.search.date = date.Format('yyyy-MM-dd');
    },
    methods: {
        handleChange() {
            this.reload();
        },
        addFavorite(){
            const ids = this.single.id;
            this.$api(this.model+'.Favorite',{ids:ids},1).then(()=>{
                this.$notice.success('课程已加入收藏');
            })
        },
        afterFirstReload(){
            this.loadSemesterListForExtra();
        },
        handleFavoriteBatch(data){
            const ids = data.ids;
            if (ids.length <1) return;
            this.$affirm('确认加入收藏吗?').then(()=>{
                this.$api(this.model+'.Favorite',{ids:ids},1).then(()=>{
                    this.$notice.success('课程已加入收藏');
                    this.reload();
                })
            });
        },
    }
}
