import XLSX from "xlsx";
import FileSaver from "file-saver"
export default {
    data() {
        return {
            show_table: true,
            table: [],
            meetings:[],
            uuid:null,
        }
    },
    name: 'Statistics',
    created() {
        this.model = 'User.Statistics';
    },
    watch:{
        view_mode:{
            handler(){
                this.searchMeetings();
            }
        }
    },
    computed: {},
    methods: {
        cleanData(){
            this.table = [];
            this.meetings = [];
            this.uuid  = null;
        },
        downloadReport() {
            this.$api(this.model + '.Statistics', {id:this.uuid}, 1).then(res => {
                this.table = res.data.data;
                this.$nextTick(function () {
                    this.exportExcel();
                })
            });
        },
        searchMeetings() {
            this.$api(this.model + '.meetings', {id: this.single.id}, 1).then(res => {
                this.meetings = res.data.data;
            });
        },
        // 导出表格
        exportExcel() {
            /* generate workbook object from table */
            let wb = XLSX.utils.table_to_book(this.$refs['excel_table'],{raw:true});
            /* get binary string as output */
            let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
            let now = new Date().Format('yyyy-MM-dd');
            try {
                FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), `与会人清单-${now}.xlsx`)
            } catch (e) {
                console.log(e, wbout)
            }
            return wbout
        },
    }
}
